import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import createDOMPurify from "dompurify";

import { Form } from "antd";
import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Button from "components/shared/Button";
import Checkbox from "components/shared/form/Checkbox";
import Radio from "components/shared/form/Radio";
import TextArea from "components/shared/form/TextArea";
import Input from "components/shared/form/Input";
import Select from "components/shared/form/CustomSelect";
import Tooltip from "components/shared/Tooltip";
import InputSlider from "components/shared/form/InputSlider";
import FileUploadBlock from "./formBlock/FileUploadBlock";
import AssetBlock from "./formBlock/asset/AssetBlock";
import PhoneNumber from "./formElements/PhoneNumber";

import { InfoCircleOutlined } from "@ant-design/icons";

import { getLeasePurposes, getIncomeSources } from "services/classifierService";
import { getCalculatorMonthlyPaymentSetup, getCalculatorMonthlyPayment, APPLICANT_ROLE } from "services/formService.js";
import { generateTranslationString } from "utils/helpers";
import { errorNotification } from "utils/notificationUtils";

const LeasingForm = ({
  formInstance,
  onFormFinish,
  initialValues,
  loading,
  legalData,
  readOnly = true,
  isPartner = false,
  prefill = false,
}) => {
  const { t } = useTranslation();

  const DOMPurify = createDOMPurify(window);
  DOMPurify.addHook("afterSanitizeAttributes", (node) => {
    // set all elements owning target to target=_blank
    if ("target" in node) {
      node.setAttribute("target", "_blank");
      node.setAttribute("rel", "noopener");
    }
  });

  const [guarantor, setGuarantor] = useState();
  const [consent, setConsent] = useState();
  const [setup, setSetup] = useState();
  const [monthlyPaymentResponse, setMonthlyPaymentResponse] = useState();

  // classifs
  const [incomeSources, setIncomeSources] = useState([]);
  const [leasePurposes, setLeasePurposes] = useState([]);

  const isPartnerQuote = initialValues.lending.partner_quote;

  useEffect(() => {
    // load classifs
    getIncomeSources().then(setIncomeSources);
    getLeasePurposes().then(setLeasePurposes);

    if (!readOnly && isPartner) {
      getCalculatorMonthlyPaymentSetup("RENT_PRIVATE").then((response) => {
        if (response.ok) {
          const isPartlyFilledFormByPartner =
            formInstance.getFieldValue(["lending", "prefill"]) &&
            APPLICANT_ROLE.isPrimary(formInstance.getFieldValue(["applicant", "role"]));

          const monthlyPaymentRequest = () => {
            return isPartlyFilledFormByPartner
              ? {
                  productType: "RENT_PRIVATE",
                  assetAmount: formInstance.getFieldValue(["lending", "property_value"]),
                  periodMonths: formInstance.getFieldValue(["lending", "period"]),
                }
              : {
                  productType: "RENT_PRIVATE",
                  assetAmount: response.payload.asset_amount.default_value,
                  periodMonths: response.payload.period_months.default_value,
                };
          };

          getMonthlyPaymentData(monthlyPaymentRequest());

          setSetup(response.payload);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    formInstance.resetFields([
      ["co_applicant", "name"],
      ["co_applicant", "surname"],
      ["co_applicant", "personal_code"],
      ["co_applicant", "phone_number"],
      ["co_applicant", "email_address"],
      ["co_applicant", "home_address"],
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guarantor]);

  const onAssetAmountChange = (val) => {
    const monthlyPaymentRequest = {
      productType: "RENT_PRIVATE",
      assetAmount: val ?? setup.asset_amount.default_value,
      periodMonths: monthlyPaymentResponse ? monthlyPaymentResponse.period_month : setup.period_months.default_value,
    };
    getMonthlyPaymentData(monthlyPaymentRequest);
  };

  const onPeriodMonthChange = (val) => {
    const monthlyPaymentRequest = {
      productType: "RENT_PRIVATE",
      assetAmount: monthlyPaymentResponse ? monthlyPaymentResponse.asset_amount : setup.asset_amount.default_value,
      periodMonths: val ?? setup.period_months.default_value,
    };
    getMonthlyPaymentData(monthlyPaymentRequest);
  };

  const getMonthlyPaymentData = async (monthlyPaymentRequest) => {
    if (!isPartner || !monthlyPaymentRequest || isNaN(monthlyPaymentRequest.assetAmount)) {
      return;
    }

    const response = await getCalculatorMonthlyPayment({ ...monthlyPaymentRequest });
    if (!response.ok) {
      errorNotification(t("error"), t("calculationError"), 5);
      return;
    }

    formInstance.setFieldsValue({
      lending: {
        property_value: response.payload.asset_amount,
        period: response.payload.period_month,
        monthly_payment: response.payload.monthly_payment_amount,
      },
    });

    setMonthlyPaymentResponse(response.payload);
  };

  const notAllowToTypeNumbers = (value, applicantType, fieldName) => {
    formInstance.setFieldsValue({
      [applicantType]: {
        [fieldName]: value?.replace(/[0-9]/g, ""),
      },
    });
  };

  return (
    <Form
      className="form"
      form={formInstance}
      onFinish={onFormFinish}
      scrollToFirstError={true}
      initialValues={initialValues}
    >
      {isPartner && !readOnly ? (
        prefill ? (
          <h3 className="invoice-verification-confirmation__heading">{t("leasingPrefillFormPartnerTitle")}</h3>
        ) : (
          <h3 className="invoice-verification-confirmation__heading">{t("leasingFormPartnerTitle")}</h3>
        )
      ) : (
        <h3 className="invoice-verification-confirmation__header">{t("g4rFormCalculator")}</h3>
      )}

      <Input name={["lending", "type"]} className="hidden" />
      {isPartner && setup && monthlyPaymentResponse ? (
        <>
          <Row>
            <Col className="slider-col" xs={24} sm={12}>
              <InputSlider
                label={t("g4rFormCarValue")}
                name={["lending", "property_value"]}
                type="decimal"
                value={monthlyPaymentResponse.asset_amount}
                onChange={onAssetAmountChange}
                sliderMin={setup.asset_amount.min}
                sliderMax={setup.asset_amount.max}
                sliderMinMaxSuffix={" €"}
              />
            </Col>
            <Col className="slider-col" xs={24} sm={12}>
              <InputSlider
                label={t("g4rFormTerm")}
                name={["lending", "period"]}
                type="decimal"
                value={monthlyPaymentResponse.period_month}
                onChange={onPeriodMonthChange}
                sliderMin={setup.period_months.min}
                sliderMax={setup.period_months.max}
                sliderMinMaxSuffix={" " + t("g4rFormTermShort")}
                sliderMarks={setup.period_months.values}
              />
            </Col>
          </Row>
          <Row>
            <Col className="monthly-payment-col" xs={24} sm={12}>
              <Row>
                <Col>
                  <label>{t("g4rFormMonthlyPayment")}</label>
                </Col>
                <Col>
                  <div className="monthly-payment">{monthlyPaymentResponse.monthly_payment_amount.toFixed(2)} €</div>
                  <Input name={["lending", "monthly_payment"]} className="hidden" />
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Col span={24}>
              <Input
                label={t("g4rFormCarValue")}
                name={["lending", "property_value"]}
                type="currency"
                disabled={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12}>
              <Input label={t("g4rFormTerm")} name={["lending", "period"]} disabled={true} />
            </Col>
            <Col xs={24} sm={12}>
              <Input
                label={t("g4rFormMonthlyPay")}
                name={["lending", "monthly_payment"]}
                type="currency"
                disabled={true}
              />
            </Col>
          </Row>
        </>
      )}

      {prefill ? (
        <h3 className="invoice-verification-confirmation__header">
          {t("leasingPrefillFormCustomerInformationBlockTitle")}
        </h3>
      ) : (
        <h3 className="invoice-verification-confirmation__header">{t("g4rFormYourInformation")}</h3>
      )}

      <Row>
        <Col xs={24} sm={12}>
          <Input name={["applicant", "type"]} className="hidden" />
          <Input
            label={t("g4rFormName")}
            name={["applicant", "name"]}
            rules={[
              {
                required: true,
                message: t("g4rFormName") + " " + t("isRequired"),
              },
              {
                max: 40,
                message: t("g4rFormFieldValueTooLong"),
              },
            ]}
            onChange={(value) => notAllowToTypeNumbers(value, "applicant", "name")}
            disabled={readOnly}
          />
        </Col>
        <Col xs={24} sm={12}>
          <Input
            label={t("g4rFormSurname")}
            name={["applicant", "surname"]}
            rules={[
              {
                required: true,
                message: t("g4rFormSurname") + " " + t("isRequired"),
              },
              {
                max: 40,
                message: t("g4rFormFieldValueTooLong"),
              },
            ]}
            onChange={(value) => notAllowToTypeNumbers(value, "applicant", "surname")}
            disabled={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={12}>
          <Input
            label={t("g4rFormPersonalCode")}
            name={["applicant", "code"]}
            rules={[
              {
                required: true,
                message: t("g4rFormPersonalCode") + " " + t("isRequired"),
              },
              {
                pattern: /^[3-6]\d{2}[0-1]\d[0-3]\d{5}$/,
                message: t("g4rFormPersonalCodeInvalid"),
              },
              {
                max: 11,
                message: t("g4rFormFieldValueTooLong"),
              },
              {
                min: 11,
                message: t("g4rFormFieldValueTooShort"),
              },
            ]}
            disabled={readOnly}
          />
        </Col>
        <Col xs={24} sm={12}>
          <Input
            label={t("g4rFormDriversLicenseNumber")}
            name={["applicant", "driving_license_number"]}
            rules={[
              {
                required: !prefill,
                message: t("g4rFormDriversLicenseNumber") + " " + t("isRequired"),
              },
              {
                max: 20,
                message: t("g4rFormFieldValueTooLong"),
              },
            ]}
            disabled={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={12}>
        <PhoneNumber
            form={formInstance}
            label={t("g4rFormPhoneNumber")}
            name={["applicant", "phone"]}
            readOnly={readOnly}
          />
        </Col>
        <Col xs={24} sm={12}>
          <Input
            label={t("g4rFormEmail")}
            name={["applicant", "email"]}
            rules={[
              {
                type: "email",
                required: true,
                message: t("g4rFormEmail") + " " + t("isRequired"),
              },
              {
                max: 80,
                message: t("g4rFormFieldValueTooLong"),
              },
            ]}
            disabled={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={12}>
          <Input
            label={t("g4rFormIncome")}
            name={["applicant", "finances", "basic", "amount"]}
            type="currency"
            rules={[
              {
                required: !prefill,
                message: t("g4rFormIncome") + " " + t("isRequired"),
              },
            ]}
            disabled={readOnly}
          />
        </Col>
        <Col xs={24} sm={12}>
          <Select
            label={`${t("g4rFormIncomeSource")}`}
            name={["applicant", "finances", "basic", "source"]}
            rules={[
              {
                required: !prefill,
                message: t("g4rFormIncomeSource") + " " + t("isRequired"),
              },
            ]}
            options={incomeSources.map((item) => {
              return {
                ...item,
                title: t(generateTranslationString(item.title, "IncomeSource")),
              };
            })}
            disabled={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={12}>
          <Input
            label={t("g4rFormAdditionalIncome")}
            name={["applicant", "finances", "additional", "amount"]}
            type="currency"
            disabled={readOnly}
          />
        </Col>
        <Col xs={24} sm={12}>
          <Input
            label={t("g4rFormAdditionalIncomeSource")}
            name={["applicant", "finances", "additional", "source"]}
            rules={[
              {
                max: 100,
                message: t("g4rFormFieldValueTooLong"),
              },
            ]}
            disabled={readOnly}
          />
        </Col>
      </Row>

      <h3 className="invoice-verification-confirmation__header">{t("g4rFormLiabilities")}</h3>
      <Row>
        <Col xs={24} sm={12}>
          <Input
            label={t("g4rFormLiabilities")}
            name={["applicant", "finances", "commitments"]}
            type="currency"
            rules={[
              {
                required: !prefill,
                message: t("g4rFormLiabilities") + " " + t("isRequired"),
              },
            ]}
            disabled={readOnly}
          />
        </Col>
      </Row>

      <h3 className="invoice-verification-confirmation__header">{t("g4rFormHomeAddress")}</h3>

      <Row>
        <Col xs={24} sm={12}>
          <Input
            label={t("g4rFormCity")}
            name={["applicant", "city"]}
            rules={[
              {
                required: !prefill,
                message: t("g4rFormCity") + " " + t("isRequired"),
              },
              {
                max: 30,
                message: t("g4rFormFieldValueTooLong"),
              },
            ]}
            disabled={readOnly}
          />
        </Col>
        <Col xs={24} sm={12}>
          <Input
            label={t("g4rFormAddress")}
            name={["applicant", "address"]}
            rules={[
              {
                required: !prefill,
                message: t("g4rFormAddress") + " " + t("isRequired"),
              },
              {
                max: 100,
                message: t("g4rFormFieldValueTooLong"),
              },
            ]}
            disabled={readOnly}
          />
        </Col>
      </Row>

      <h3 className="invoice-verification-confirmation__header">
        {t("g4rFormGuarantorDisclaimer")}
        <Tooltip
          title={
            <p className="public-form__comment public-form__comment--go4rent-theme">{t("g4rFormGuarantorTooltip")}</p>
          }
          placement="right"
        >
          <InfoCircleOutlined className="public-form__comment-icon" />
        </Tooltip>
      </h3>

      <Row>
        <Col xs={24} sm={12}>
          <Select
            name={["meta", "is_guarantor_selected"]}
            options={[
              { key: 1, value: false, title: t("g4rFormIsGuarantorSelectedNo") },
              { key: 2, value: true, title: t("g4rFormIsGuarantorSelectedYes") },
            ]}
            value={guarantor}
            onChange={setGuarantor}
            disabled={readOnly}
          />
        </Col>
      </Row>

      {guarantor ? (
        <>
          <h3 className="invoice-verification-confirmation__header">{t("g4rFormGuarantorInformation")}</h3>
          <Row>
            <Col xs={24} sm={12}>
              <Input name={["co_applicant", "type"]} className="hidden" />
              <Input
                label={t("g4rFormGuarantorName")}
                name={["co_applicant", "name"]}
                rules={[
                  {
                    max: 30,
                    message: t("g4rFormFieldValueTooLong"),
                  },
                ]}
                onChange={(value) => notAllowToTypeNumbers(value, "co_applicant", "name")}
                disabled={readOnly}
              />
            </Col>
            <Col xs={24} sm={12}>
              <Input
                label={t("g4rFormGuarantorSurname")}
                name={["co_applicant", "surname"]}
                rules={[
                  {
                    max: 30,
                    message: t("g4rFormFieldValueTooLong"),
                  },
                ]}
                onChange={(value) => notAllowToTypeNumbers(value, "co_applicant", "surname")}
                disabled={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Input
                label={t("g4rFormGuarantorPersonalCode")}
                name={["co_applicant", "code"]}
                rules={[
                  {
                    pattern: /^[3-6]\d{2}[0-1]\d[0-3]\d{5}$/,
                    message: t("g4rFormPersonalCodeInvalid"),
                  },
                  {
                    max: 11,
                    message: t("g4rFormFieldValueTooLong"),
                  },
                  {
                    min: 11,
                    message: t("g4rFormFieldValueTooShort"),
                  },
                ]}
                disabled={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12}>
              <PhoneNumber
                form={formInstance}
                label={t("g4rFormPhoneNumber")}
                name={["co_applicant", "phone"]}
                readOnly={readOnly}
              />
            </Col>
            <Col xs={24} sm={12}>
              <Input
                label={t("g4rFormGuarantorEmail")}
                name={["co_applicant", "email"]}
                rules={[
                  {
                    type: "email",
                    message: t("g4rFormGuarantorEmail") + " " + t("isRequired"),
                  },
                  {
                    max: 80,
                    message: t("g4rFormFieldValueTooLong"),
                  },
                ]}
                disabled={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12}>
              <Input
                label={t("g4rFormGuarantorCity")}
                name={["co_applicant", "city"]}
                rules={[
                  {
                    max: 30,
                    message: t("g4rFormFieldValueTooLong"),
                  },
                ]}
                disabled={readOnly}
              />
            </Col>
            <Col xs={24} sm={12}>
              <Input
                label={t("g4rFormGuarantorAddress")}
                name={["co_applicant", "address"]}
                rules={[
                  {
                    max: 100,
                    message: t("g4rFormFieldValueTooLong"),
                  },
                ]}
                disabled={readOnly}
              />
            </Col>
          </Row>
        </>
      ) : null}

      <AssetBlock
        formInstance={formInstance}
        feeTooltip={legalData.propertyValue}
        insuranceTooltip={legalData.propertyInsurance}
        readOnly={readOnly}
        isPartner={isPartner}
      />

      <h3 className="invoice-verification-confirmation__header">{t("g4rFormLeasePurpose")}</h3>
      <Row>
        <Col span={24}>
          <Form.Item name={["applicant", "lease_purpose"]}>
            <Radio
              options={leasePurposes.map((item) => {
                return {
                  ...item,
                  label: t(generateTranslationString(item.title, "LeasePurpose")),
                };
              })}
              disabled={readOnly}
            />
          </Form.Item>
        </Col>
      </Row>

      <h3 className="invoice-verification-confirmation__header">{t("g4rFormAdditionalInfo")}</h3>
      <Row className="hidden-ant-form-item-label">
        <Col span={24}>
          <TextArea
            label={t("g4rFormAdditionalInfo")}
            name={["applicant", "additional_information"]}
            rules={[
              {
                max: 250,
                message: t("g4rFormFieldValueTooLong"),
              },
            ]}
            disabled={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FileUploadBlock
            readOnly={readOnly}
            formInstance={formInstance}
            productType={`RENT_${initialValues.applicant.type}`}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item name={["applicant", "is_consent_of_data_processing_agreed"]} valuePropName="checked">
            <Checkbox onChange={setConsent} checked={consent} className="public-form__checkbox" disabled={readOnly}>
              <span
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(legalData.confirmation?.body?.[0]?.value) }}
              ></span>
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Form.Item
            name={["applicant", "is_marketing_consent"]}
            valuePropName="checked"
            className={isPartner || isPartnerQuote ? "" : "hidden"}
          >
            <Checkbox className="public-form__checkbox" disabled={readOnly}>
              <span
                style={{ display: "inline-block" }}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(legalData.marketing?.body?.[0]?.value) }}
              ></span>
              <Tooltip
                placement="right"
                title={
                  <p
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(legalData.marketingTooltip?.body?.[0]?.value),
                    }}
                  />
                }
              >
                <InfoCircleOutlined className="public-form__comment-icon" />
              </Tooltip>
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      {isPartner ? (
        <>
          <Input name={["applicant", "token"]} className="hidden" />
          <Input name={["lending", "prefill"]} className="hidden" />
          <Input name={["lending", "partner_quote"]} className="hidden" />
          <Input name={["lending", "originator_token"]} className="hidden" />
        </>
      ) : (
        <Input name={["lending", "pipedrive_id"]} className="hidden" />
      )}

      {!readOnly &&
        (prefill ? (
          <Button loading={loading} type="primary" size="large" htmlType="submit">
            {t("prefillFormSubmitButton")}
          </Button>
        ) : (
          <Button disabled={!consent} loading={loading} type="primary" size="large" htmlType="submit">
            {t("g4rFormSubmit")}
          </Button>
        ))}
    </Form>
  );
};

export default LeasingForm;
