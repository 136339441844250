import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import cx from "classnames";

import { Form, Input } from "antd";

import { requiredField, defaultLabelText as emptyLabelText, stripStringToCurrency } from "utils/formUtils";

const CustomInput = ({
  type,
  name,
  rules,
  label = " ",
  value,
  onChange = () => undefined,
  className,
  normalize,
  ...rest
}) => {
  const inputRef = useRef();

  const isRequired = !!rules?.filter((i) => !!i.required).length;
  const hasCurrencyMask = type === "currency";
  const defaultLabelText = isRequired ? requiredField(label) : label;
  const [labelText, setLabelText] = useState();
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    // catch initial value on render
    setCurrentValue(inputRef.current.props.value);
    onChange(inputRef.current.props.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    // intercept value from parent
    setCurrentValue(value);
  }, [value]);

  useEffect(() => {
    if (currentValue) {
      setLabelText(defaultLabelText);
    } else {
      setLabelText(emptyLabelText);
    }
  }, [currentValue, defaultLabelText]);

  const onChangeWrapper = (value_) => {
    setCurrentValue(value_); // intercept value
    onChange && onChange(value_); // propagate up
  };

  const normalizeWraper = (...args) => {
    if (hasCurrencyMask) {
      return (normalize && normalize(stripStringToCurrency(args[0]), ...args.slice(1))) || stripStringToCurrency(args[0]);
    }
    return (normalize && normalize(...args)) || args[0];
  };

  return (
    <>
      <Form.Item
        label={labelText}
        name={name}
        rules={rules}
        className={cx("input-wrapper", className)}
        normalize={normalizeWraper}
      >
        <Input
          className={cx("input", className)}
          placeholder={defaultLabelText}
          onChange={(event) => onChangeWrapper(event.target.value)}
          value={currentValue}
          ref={inputRef}
          {...rest}
        />
      </Form.Item>
    </>
  );
};

export default CustomInput;
