import settings from "config/config";
import fetchWrapper from "./fetchWrapper";

export const APPLICANT_ROLE = {
  PRIMARY: "PRIMARY APPLICANT",
  GUARANTOR: "GUARANTOR",

  isPrimary: (role) => role === APPLICANT_ROLE.PRIMARY,
  isGuarantor: (role) => role === APPLICANT_ROLE.GUARANTOR,
};

export const FINANCING_COMPANY = {
  G4R: "G4R",
};

// Financing request
const backendApiUrl = settings.backendApiUrl + settings.go4rentEndpoint;
const financingRequestValidationUrl = backendApiUrl + "/form/financial-request/validate";
const prefillFinancingRequestValidationUrl = backendApiUrl + "/form/financial-request/prefill/validate";
const financingRequestUrl = backendApiUrl + "/form/financial-request/apply";
const prefillFinancingRequestUrl = backendApiUrl + "/form/financial-request/prefill/apply";

export const validateFinancingRequest = async (data) => fetchWrapper.postJSON(financingRequestValidationUrl, data);
export const validatePrefillFinancingRequest = async (data) =>
  fetchWrapper.postJSON(prefillFinancingRequestValidationUrl, data);
export const postFinancingRequest = async (data) => fetchWrapper.postJSONreceiveJSON(financingRequestUrl, data);
export const postPrefillFinancingRequest = async (data) =>
  fetchWrapper.postJSONreceiveJSON(prefillFinancingRequestUrl, data);

// Data access & update
export const getApplicantData = (applicationToken, applicantToken, code) =>
  fetchWrapper.getJSON(`${backendApiUrl}/form/financial-request/${applicationToken}/applicants/${applicantToken}`, {
    code,
  });
export const getValidatedApplicant = (applicationToken, applicantToken) =>
  fetchWrapper.getJSON(
    `${backendApiUrl}/form/financial-request/${applicationToken}/applicants/${applicantToken}/validate`
  );
export const validateApplicantData = (applicationToken, applicantToken, data) =>
  fetchWrapper.postJSON(
    `${backendApiUrl}/form/financial-request/${applicationToken}/applicants/${applicantToken}/validate`,
    data
  );
export const postApplicantData = (applicationToken, applicantToken, data) =>
  fetchWrapper.postJSONreceiveJSON(
    `${backendApiUrl}/form/financial-request/${applicationToken}/applicants/${applicantToken}`,
    data
  );

// Dokobit

const dokobitSigningStatusUrl = backendApiUrl + "/form/dokobit/document/status";
export const getDokobitSingingStatus = async (token) => fetchWrapper.getText(dokobitSigningStatusUrl, { token });

// Nordigen

const institutionsUrl = backendApiUrl + "/form/institutions";

export const getBankList = async (country) => fetchWrapper.getJSON(institutionsUrl, { country });

export const getRedirectUrl = () => window.location.origin + "/leasingRequest";

export const postBankSelection = async (institution_id, md5_code, language, registration_code) =>
  fetchWrapper.postJSONreceiveJSON(
    institutionsUrl,
    { institution_id, md5_code, language, registration_code },
    {
      Link: getRedirectUrl(),
    }
  );

// partner
const partnerTokenValidationUrl = backendApiUrl + "/form/partner/validate";
const partnerPrivateTokenValidationUrl = backendApiUrl + "/form/partner/prefill/validate";
const partnerLogoUrl = (originatorToken) => `${backendApiUrl}/form/partner/${originatorToken}/logo`;

export const validatePartnerTokens = async (originatorToken) =>
  fetchWrapper.getText(
    new URL(partnerTokenValidationUrl),
    {
      originatorToken,
    },
    {
      From: FINANCING_COMPANY.G4R,
    }
  );

export const validatePartnerPrivateToken = (originatorPrivateToken) =>
  fetchWrapper.getJSON(new URL(partnerPrivateTokenValidationUrl), {
    originatorPrivateToken,
  });

export const getPartnerLogo = async (originatorToken) =>
  fetchWrapper.getFile(partnerLogoUrl(originatorToken), undefined, undefined, true);

// local storage
const formStoreId = "go4RentForm";
const formStoredFileId = formStoreId + "File";
export const saveToLocalStorage = (data) => {
  const filesArray = data.applicant.files ? data.applicant.files : [];
  filesArray.forEach((file, index) => {
    localStorage.setItem(formStoredFileId + index, file.fileContent);
  });

  const applicant = {
    ...data.applicant,
    files: filesArray.map((file) => ({
      fileName: file.fileName,
      documentType: file.documentType,
      fileSize: file.fileSize,
    })),
  };

  localStorage.setItem(formStoreId, JSON.stringify({ ...data, applicant: applicant }));
};
export const getFromLocalStorage = () => {
  const data = JSON.parse(localStorage.getItem(formStoreId));
  let filesArray = [];
  if (data) {
    filesArray = data.applicant?.files?.map((file, index) => ({
      fileName: file.fileName,
      documentType: file.documentType,
      fileSize: file.fileSize,
      fileContent: localStorage.getItem(formStoredFileId + index),
    }));

    const applicant = { ...data.applicant, files: filesArray };

    return {
      ...data,
      applicant: applicant,
    };
  }
};
export const cleanLocalStorage = () => {
  const filesArray = JSON.parse(localStorage.getItem(formStoreId))?.applicant?.files;
  if (filesArray) {
    filesArray.forEach((file, index) => localStorage.removeItem(formStoredFileId + index));
  }
  localStorage.removeItem(formStoreId);
};

// calculator
const calculatorMonthlyPaymentSetupUrl = settings.backendApiUrl + "/calculator/monthly-payment/setup";
const calculatorMonthlyPaymentUrl = settings.backendApiUrl + "/calculator/monthly-payment/calculate";

export const getCalculatorMonthlyPaymentSetup = async (productType) =>
  fetchWrapper.getJSON(calculatorMonthlyPaymentSetupUrl, { productType });

export const getCalculatorMonthlyPayment = async (params) =>
  fetchWrapper.getJSON(calculatorMonthlyPaymentUrl, {
    ...params,
  });

// Type definitions
export const APPLICANT_TYPES = {
  PRIVATE: "PRIVATE",
  BUSINESS: "BUSINESS",

  isPrivate: (type) => {
    return type === APPLICANT_TYPES.PRIVATE;
  },
  isBusiness: (type) => type === APPLICANT_TYPES.BUSINESS,
};

export const storeFinancingCompany = (service) => {
  if (!service) {
    return;
  }

  sessionStorage.setItem("financingCompany", service);
};

export const getStoredFinancingCompany = () => {
  return sessionStorage.getItem("financingCompany") || null;
};
