import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import cx from "classnames";
import PropTypes from "prop-types";

const CustomButton = ({ to, type, size, children, className, icon, ...props }) => (
  <Button
    size={size}
    icon={icon}
    className={cx(
      "button",
      { [`button--${type}`]: type, [`button--${size}`]: size, "button--theme-go4rent": "go4rent" },
      { "button--icon-only": !type && !size && icon },
      className
    )}
    {...props}
  >
    {to ? <Link to={to}>{children}</Link> : children}
  </Button>
);

CustomButton.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf(["primary", "default", "secondary"]),
  size: PropTypes.oneOf(["small", "large"]),
  children: PropTypes.node,
  onClick: PropTypes.func,
  icon: PropTypes.node,
  shape: PropTypes.oneOf(["circle", "round"]),
};

export default CustomButton;
