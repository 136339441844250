const getSizeMBToBytes = (megaBytes) => {
  return megaBytes * Math.pow(1024, 2);
};

export const isFileSizeValid = (filesSizeInBytes, maxFilesSizeInMB) => {
  return filesSizeInBytes <= getSizeMBToBytes(maxFilesSizeInMB);
};

export const hasValidFileTypes = (fileNameArray, extensionsRegex) => {
  const regex = new RegExp(extensionsRegex);
  return fileNameArray.every((fileName) => regex.test(fileName));
};
