import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Input from "components/shared/form/Input";
import Select from "components/shared/form/CustomSelect";

import FormHeader from "components/forms/formElements/FormHeader";
import InputTooltip from "components/forms/formElements/InputTooltip";

const AssetFeeBlock = ({ formInstance, tooltip, readOnly = false }) => {
  const { t } = useTranslation();

  const [registrationFee, setRegistrationFee] = useState();

  useEffect(() => {
    formInstance.resetFields([["lending", "included_taxes_amount"]]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrationFee]);

  return (
    <>
      <FormHeader text={t("g4rFormIncludePropertyTaxes")} tooltip={tooltip} />
      <Row>
        <Col xs={24} sm={12}>
          <Select
            name={["lending", "is_registration_fee_included"]}
            options={[
              { key: 1, value: false, title: t("g4rFormIncludePropertyTaxesNo") },
              { key: 2, value: true, title: t("g4rFormIncludePropertyTaxesYes") },
            ]}
            value={registrationFee}
            onChange={setRegistrationFee}
            disabled={readOnly}
          />
        </Col>
      </Row>
      {registrationFee && (
        <Row>
          <Col xs={24} sm={12}>
            <Input
              label={t("g4rFormIncludedPropertyTaxesSum")}
              name={["lending", "included_taxes_amount"]}
              type="currency"
              suffix={
                !readOnly ? <InputTooltip innerHtml={t("g4rFormIncludedPropertyTaxesSumComment")} /> : null // this requires proper fix - removal of all dynamic css in input component => scss
              }
              disabled={readOnly}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default AssetFeeBlock;
