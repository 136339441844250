export const defaultLabelText = "\xa0";
export const defaultFilledClassName = "filled";

export const requiredField = (value) => {
  return `${value} *`;
};

export const stripStringToCurrency = (value) => {
  return value ? value.replace(/[^0-9.,]/g, "").replace(",", ".") : "";
};

export const stripStringToInteger = (value) => {
  return value ? value.replace(/\D/g, "") : "";
};

export const stripNumbers = (value) => {
  return value ? value.replace(/\d/g, "") : "";
}
