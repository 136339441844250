import { notification } from "antd";

const defaultNotificationDuration = 10;

export const successNotification = (message, description, notificationDuration) => {
  notification.success({
    message: message,
    description: description,
    duration: notificationDuration ? notificationDuration : defaultNotificationDuration,
  });
};

export const errorNotification = (message, description, notificationDuration) => {
  notification.error({
    message: message,
    description: description,
    duration: notificationDuration ? notificationDuration : defaultNotificationDuration,
  });
};
