import settings from "config/config";
import { getAuthHeader } from "./guardService";

const classifierBaseUrl = settings.backendApiUrl + settings.go4rentEndpoint + "/form/classifier/";

const classifierRequestWrapper = (list, group = null) => {
  const url = new URL(list, classifierBaseUrl);
  if (group) {
    url.search = new URLSearchParams({ group });
  }

  return fetch(url, getAuthHeader())
    .then((response) => (response.ok ? response.json() : { values: [] }))
    .then((data) =>
      data.values?.map((item) => {
        return {
          key: item.code,
          value: item.code,
          title: item.description || item.code,
          label: item.description || item.code,
        };
      })
    );
};

export const getIncomeSources = async () => classifierRequestWrapper("income-sources");

export const getAssetBrands = async () => classifierRequestWrapper("asset-brands");

export const getAssetModels = async (manufacturer) => classifierRequestWrapper("asset-models", manufacturer);

export const getAssetFuelTypes = async () => classifierRequestWrapper("asset-fuel-types");

export const getAssetBodyTypes = async () => classifierRequestWrapper("asset-body-types");

export const getAssetYears = async () => classifierRequestWrapper("asset-years");

export const getAssetTaxes = async () => classifierRequestWrapper("asset-taxes");

export const getLeasePurposes = async () => classifierRequestWrapper("lease-purposes");

export const getAssetDocumentType= async (productType) => classifierRequestWrapper("asset-document-type", productType);

export const getCallingCodes = async () => classifierRequestWrapper("asset-calling-codes");
