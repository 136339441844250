import React from "react";
import { Route } from "react-router-dom";
import PublicLayout from "../../layouts/publicLayout";

const PublicRoute = ({ component: Component, formType, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <PublicLayout formType={formType}>
          <Component {...props} />
        </PublicLayout>
      )}
    />
  );
};

export default PublicRoute;
