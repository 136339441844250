import settings from "config/config";
import { getAuthHeader } from "./guardService";

const changeAccountUrl = settings.backendApiUrl + settings.go4rentEndpoint + "/user/account";

export const changeSelectedLanguage = async (data) => {
  try {
    const response = await fetch(changeAccountUrl, {
      method: "PATCH",
      headers: {
        ...{
          "Content-Type": "application/json",
        },
        ...getAuthHeader().headers,
      },
      body: JSON.stringify(data),
    });
    return await response.text();
  } catch (e) {
    console.log(e);
    return {
      error: true,
      message: e,
    };
  }
};

export const saveToLocalStorage = ({ user, token, parties, assignedManager }) => {
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("token", JSON.stringify(token));
  localStorage.setItem("assignedManager", JSON.stringify(assignedManager || ""));

  if (Array.isArray(parties)) {
    localStorage.setItem("parties", JSON.stringify(parties));
  }
};

export const getFromLocalStorage = () => {
  const user = JSON.parse(localStorage.getItem("user") || null);
  const token = JSON.parse(localStorage.getItem("token") || null);
  const parties = JSON.parse(localStorage.getItem("parties") || null);
  const assignedManager = JSON.parse(localStorage.getItem("assignedManager") || null);

  return {
    user,
    token,
    parties,
    assignedManager,
  };
};

export const clearLocalStorage = () => {
  localStorage.clear();
};
