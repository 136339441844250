import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import settings from "../config/config";
import { getCmsTokenValue } from "services/guardService";

i18n
  .use(HttpApi)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "lt",
    fallbackLng: "lt",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    //Options for Loading resources
    backend: {
      loadPath: `${settings.backendApiUrl}/cms/translations?language={{lng}}`,

      customHeaders: {
        authorization: getCmsTokenValue(),
      },

      requestOptions: {
        mode: "cors",
        credentials: "same-origin",
        cache: "default",
      },
    },
  });

export default i18n;
