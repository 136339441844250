import { APPLICANT_ROLE } from "services/formService";

export const getFormSuccessStateMessage = (applicant) => {
  switch (applicant) {
    case APPLICANT_ROLE.PRIMARY:
      return "g4rFormSuccessState";
    case APPLICANT_ROLE.GUARANTOR: 
      return "g4rFormSuccessStateGuarantor";
    default: 
      return "g4rFormSuccessState";
  }
};
