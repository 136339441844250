const getCountriesApiUrl = "/data/countries.json";

export const getCountriesWithPhoneCodes = async () => {
  let items = [];
  const keyName = "countriesList";
  try {
    const savedItems = localStorage.getItem(keyName);

    if (!savedItems) {
      const response = await fetch(getCountriesApiUrl);

      items = await response.json();

      localStorage.setItem(keyName, JSON.stringify(items));
    } else {
      items = JSON.parse(savedItems);
    }

    return items;
  } catch (e) {
    console.log(e);
    return { error: true, message: e };
  }
};
