import React, { useState, useEffect } from "react";
import "assets/scss/components/formElements/_phone-number.scss";
import { getCallingCodes } from "services/classifierService";
import { useTranslation } from "react-i18next";
import { requiredField } from "utils/formUtils";
import { Select, Form, Input } from "antd";
import Input2 from "components/shared/form/Input";
import cx from "classnames";
import Flag from "react-world-flags";
import countries from "assets/countries.json";
const { Option } = Select;

const LITHUANIA_COUNTRY_CODE = "LT";
const MIN_LITHUANIAN_NUMBER_LENGTH = 8;
const MAX_LITHUANIAN_NUMBER_LENGTH = 8;
const MIN_NON_LITHUANIAN_NUMBER_LENGTH = 5;
const MAX_NON_LITHUANIAN_NUMBER_LENGTH = 13;

const PhoneNumber = ({ form, name, label, readOnly = false }) => {
  const { t } = useTranslation();
  const [callingCodes, setCallingCodes] = useState([]);
  const [number, setNumber] = useState();
  const [prefix, setPrefix] = useState();

  useEffect(() => {
    getCallingCodes().then((response) => {
      setCallingCodes(
        response.filter(
          (callingCode) => countries.findIndex((country) => country.alpha2 === callingCode.value.toLowerCase()) !== -1
        )
      );

      if (phoneNumber) {
        const phoneNumberWithoutPlus = phoneNumber.replace("+", "");
        const countryCode = response.find((countryCode) => phoneNumberWithoutPlus.startsWith(countryCode.title));
        const numberWithoutCode = phoneNumberWithoutPlus.replace(countryCode.title, "");

        setInitInputValue(numberWithoutCode, countryCode?.value);
      } else {
        setInitInputValue(null, LITHUANIA_COUNTRY_CODE);
      }
    });

    const phoneNumber = form.getFieldValue(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const callingCode = callingCodes.find((callingCode) => callingCode.value === prefix)?.title;

    form.setFieldsValue({
      [name[0]]: {
        [name[1]]: "+" + callingCode + number,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefix, number]);

  const selectValidator = () => {
    if (!prefix) {
      return Promise.reject(`${t("phone")} ${t("isRequired")}`);
    }

    return Promise.resolve();
  };

  const setInitInputValue = (number, countryCode) => {
    setNumber(number);
    setPrefix(countryCode);

    // Hack, because the initial values of this form is set
    form.setFieldsValue({
      phone: { number: { [name[0]]: number } },
    });
    form.setFieldsValue({
      phone: { prefix: { [name[0]]: countryCode } },
    });
  };

  const getMinNumberLength = () =>
    prefix === LITHUANIA_COUNTRY_CODE ? MIN_LITHUANIAN_NUMBER_LENGTH : MIN_NON_LITHUANIAN_NUMBER_LENGTH;

  const getMaxNumberLength = () =>
    prefix === LITHUANIA_COUNTRY_CODE ? MAX_LITHUANIAN_NUMBER_LENGTH : MAX_NON_LITHUANIAN_NUMBER_LENGTH;

  const getOptionLable = (item) => (
    <>
      <Flag className="flag" code={item.value} height={20} width={20} /> {"+" + item.title}{" "}
    </>
  );
  const getOptionDescription = (item) => (
    <>
      <Flag className="flag" code={item.value} height={20} width={20} />{" "}
      {" " + countries.find((country) => country.alpha2 === item.value.toLowerCase()).name + " +" + item.title}
    </>
  );

  const prefixSelector = (
    <Form.Item name={["phone", "prefix", name[0]]}>
      <Select
        className="phone-code"
        value={prefix}
        showSearch={true}
        onChange={(value) => setPrefix(value)}
        disabled={readOnly}
        optionLabelProp="label"
      >
        {callingCodes.length &&
          callingCodes.map((item, i) => (
            <Option key={i} value={item.value} label={getOptionLable(item)}>
              {getOptionDescription(item)}
            </Option>
          ))}
      </Select>
    </Form.Item>
  );
  return (
    <div className="phone-number-wrapper">
      <Input2 className="hide" label={label} name={name} disabled={true} />
      <Form.Item
        label={number ? requiredField(label) : " "}
        name={["phone", "number", name[0]]}
        dependencies={[["phone", "prefix", name[0]]]}
        rules={[
          {
            required: true,
            message: `${t("phone")} ${t("isRequired")}`,
          },
          {
            validator: (_, value) => selectValidator(),
          },
          {
            max: getMaxNumberLength(),
            message: t("g4rFormFieldValueTooLong"),
          },
          {
            min: getMinNumberLength(),
            message: t("g4rFormFieldValueTooShort"),
          },
          {
            pattern: /^[0-9]*$/,
            message: t("formFieldValueNotNumber"),
          },
        ]}
        validateTrigger={["onBlur", "onChange"]}
      >
        <Input
          className={cx("phone-number-input", {
            "input-active-border": (undefined !== number || undefined !== prefix) && !readOnly,
          })}
          value={number}
          placeholder={requiredField(t("g4rFormPhoneNumber"))}
          onChange={(event) => setNumber(event.target.value)}
          addonBefore={prefixSelector}
          disabled={readOnly}
        />
      </Form.Item>
    </div>
  );
};

export default PhoneNumber;
