import React from "react";
import settings from "config/config";
import { useTranslation } from "react-i18next";

import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Input from "components/shared/form/Input";
import Select from "components/shared/form/CustomSelect";

import { CloudUploadOutlined, MinusCircleOutlined } from "@ant-design/icons";
import Button from "components/shared/Button";
import DragAndDrop from "components/forms/formElements/DragAndDrop";
import { useState, useEffect } from "react";
import { getAssetDocumentType } from "services/classifierService";
import { isFileSizeValid, hasValidFileTypes } from "utils/fileUtils";

const FileUploadBlock = ({ readOnly, formInstance, productType }) => {
  const { t } = useTranslation();
  const [fileArray, setFileArray] = useState([]);
  const [documetTypes, setDocumentTypes] = useState([]);
  const [errorMessage, setErrorMessage] = useState();

  const handleFileUpload = (uploadedFileArray) => {
    if (!hasErrors(uploadedFileArray)) {
      uploadedFileArray.forEach((file) => {
        encodeFileInBase64(file);
      });
    }
  };

  const handleFileRemoval = (file, index) => {
    setFileArray(fileArray.filter((file, i) => i !== index));
  };

  useEffect(() => {
    if (formInstance.getFieldValue(["applicant", "files"])) {
      setFileArray(
        formInstance.getFieldValue(["applicant", "files"]).map((file) => ({
          fileName: file.fileName,
          fileContent: file.fileContent,
          fileSize: file.fileSize,
          documentType: file.documentType,
        }))
      );
    }

    getAssetDocumentType(productType).then((assetDocumentTypes) =>
      setDocumentTypes(assetDocumentTypes.map((documentType, index) => ({ ...documentType, key: index })))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    formInstance.setFieldsValue({
      applicant: {
        files: fileArray,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileArray.length]);

  const encodeFileInBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result.split(",")[1];
      setFileArray((fileArray) => [
        ...fileArray,
        {
          fileName: file.name,
          fileContent: base64String,
          fileSize: file.size,
          documentType: null,
        },
      ]);
    };
    reader.onerror = function (error) {
      setError(t("fileUploadFileIsCorrupt"));
    };
  };

  const hasErrors = (uploadedFileArray) => {
    const filesSizeInBytes = fileArray.map((file) => file.fileSize).reduce((accumulator, a) => accumulator + a, 0);
    const uploadedFilesSizeInBytes = uploadedFileArray
      .map((file) => file.size)
      .reduce((accumulator, a) => accumulator + a, 0);
    const uploadedFilesNames = uploadedFileArray.map((file) => file.name);

    const totalSize = filesSizeInBytes + uploadedFilesSizeInBytes;
    if (!isFileSizeValid(totalSize, settings.financialForm.fileUpload.sizeInMB)) {
      setError(t("fileUploadFilesExceedsSizeLimit"));
      return true;
    }

    if (!hasValidFileTypes(uploadedFilesNames, settings.financialForm.fileUpload.extensionsRegex)) {
      setError(t("fileUploadFileTypeNotSupported"));
      return true;
    }

    return false;
  };

  const onDocumentTypeChange = (index) => {
    const selectedDocumentType = formInstance.getFieldValue(["applicant", "files", index, "documentType"]);
    const fileArrayCopy = [...fileArray];
    fileArrayCopy[index].documentType = selectedDocumentType;
    setFileArray(fileArrayCopy);
  };

  const setError = (message) => {
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage(null);
    }, 5000);
  };

  return (
    <>
      <Row className="fileUploadBlockWrapper">
        {!readOnly && 
          <Col span={24}>
            <DragAndDrop handleFileUpload={handleFileUpload}>
              <div className="dragAndDropChildrenWrapper">
                <CloudUploadOutlined />
                <h5>{t("fileUploadSectionHeader")}</h5>
                <Button className="dragAndDropChildrenWrapper__button" size="small">
                  {t("fileUploadSectionButton")}
                </Button>
              </div>
              {errorMessage && <div className="errorMessage">{errorMessage}</div>}
            </DragAndDrop>
          </Col>
        }
        <Col span={24}>
          {fileArray &&
            fileArray.map((file, index) => (
              <Row key={index} className="fileUploadBlock">
                <Col span={11}>
                  <Input
                    type="text"
                    name={["applicant", "files", index, "fileName"]}
                    label={t("fileUploadSectionInputFileName")}
                    disabled
                  />
                </Col>
                <Col span={11}>
                  <Select
                    label={t("fileUploadSectionInputDocumentType")}
                    name={["applicant", "files", index, "documentType"]}
                    onChange={() => onDocumentTypeChange(index)}
                    options={documetTypes}
                    disabled={readOnly}
                    rules={[
                      {
                        required: true,
                        message: t("additionalInformationFileType") + " " + t("isRequired"),
                      },
                    ]}
                  />
                </Col>
                <Col span={0} className="fileInput">
                  <Input type="file" name={["applicant", "files", index, "fileContent"]} disabled />
                </Col>
                {!readOnly && (
                  <Col span={2}>
                    <div className="minusIconWrapper" onClick={() => handleFileRemoval(file, index)}>
                      <MinusCircleOutlined />
                    </div>
                  </Col>
                )}
              </Row>
            ))}
        </Col>
      </Row>
    </>
  );
};

export default FileUploadBlock;
