import React from "react";
import PropTypes from "prop-types";
import g4rLogo from "assets/logo/g4r_logo.svg";

const Logo = ({ responsive }) => {
  return <img src={g4rLogo} alt="logo" className={`logo ${responsive ? "logo--responsive" : ""}`} />;
};

Logo.propTypes = {
  response: PropTypes.bool,
};

Logo.defaultProps = {
  responsive: true,
};

export default Logo;
