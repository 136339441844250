import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getBankList } from "services/formService";
import ModalListSelect from "components/shared/ModalListSelect";

const BankSelector = ({ onFinish }) => {
  const { t } = useTranslation();
  const [bankList, setBankList] = useState([]);

  useEffect(() => {
    getBankList("LT").then((response) => {
      if (response.ok) {
        setBankList(
          response.payload.map((item) => ({
            ...item,
            img: item.name,
            externalImg: item.logo,
          }))
        );
      }
    });
  }, []);

  return (
    <ModalListSelect
      options={bankList}
      onFinish={onFinish}
      headingText={t("g4rBankSelectorChooseBank")}
      okText={t("g4rBankSelectorSelect")}
    />
  );
};

export default BankSelector;
