import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import createDOMPurify from "dompurify";
import { InfoCircleOutlined } from "@ant-design/icons";

import { Form } from "antd";
import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Button from "components/shared/Button";
import Checkbox from "components/shared/form/Checkbox";
import TextArea from "components/shared/form/TextArea";
import Input from "components/shared/form/Input";
import Tooltip from "components/shared/Tooltip";
import FileUploadBlock from "./formBlock/FileUploadBlock";

import AssetInformationDisplayBlock from "./AssetInformationDisplayBlock";
import PrivateCustomerInformationBlock from "./PrivateCustomerInformationBlock";
import BusinessCustomerInformationBlock from "./BusinessCustomerInformationBlock";
import { APPLICANT_TYPES } from "services/formService";

const GuarantorForm = ({ formInstance, onFormFinish, initialValues, loading, legalData }) => {
  const { t } = useTranslation();

  const DOMPurify = createDOMPurify(window);
  DOMPurify.addHook("afterSanitizeAttributes", (node) => {
    // set all elements owning target to target=_blank
    if ("target" in node) {
      node.setAttribute("target", "_blank");
      node.setAttribute("rel", "noopener");
    }
  });
  const [consent, setConsent] = useState(!!initialValues?.is_consent_of_data_processing_agreed);

  return (
    <Form
      className="form"
      form={formInstance}
      onFinish={onFormFinish}
      scrollToFirstError={true}
      initialValues={initialValues}
    >
      <h3 className="invoice-verification-confirmation__header">{t("leasingFormRelatedApplicantTitleGuarantor")}</h3>

      {APPLICANT_TYPES.isPrivate(initialValues.applicant.type) ? (
        <PrivateCustomerInformationBlock formInstance={formInstance} asRelatedApplicant />
      ) : (
        <BusinessCustomerInformationBlock formInstance={formInstance} asRelatedApplicant />
      )}

      <h3 className="invoice-verification-confirmation__header">{t("leasingFormMainApplicantTitle")}</h3>
      <Row>
        <Col xs={24} sm={12}>
          <Input label={t("g4rFormName")} name={["co_applicant", "name"]} disabled />
        </Col>
        <Col xs={24} sm={12}>
          <Input label={t("g4rFormSurname")} name={["co_applicant", "surname"]} disabled />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Input label={t("g4rFormPersonalCode")} name={["co_applicant", "code"]} disabled />
        </Col>
      </Row>

      <AssetInformationDisplayBlock formInstance={formInstance} />

      <h3 className="invoice-verification-confirmation__header">{t("g4rFormAdditionalInfo")}</h3>
      <Row className="hidden-ant-form-item-label">
        <Col span={24}>
          <TextArea
            label={t("g4rFormAdditionalInfo")}
            name={["applicant", "additional_information"]}
            rules={[
              {
                max: 250,
                message: t("g4rFormFieldValueTooLong"),
              },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FileUploadBlock
            readOnly={false}
            formInstance={formInstance}
            productType={`RENT_${initialValues.applicant.type}`}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item name={["applicant", "is_consent_of_data_processing_agreed"]} valuePropName="checked">
            <Checkbox onChange={setConsent} checked={consent} className="public-form__checkbox">
              <span
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(legalData.confirmation?.body?.[0]?.value) }}
              ></span>
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Form.Item name={["applicant", "is_marketing_consent"]} valuePropName="checked">
            <Checkbox className="public-form__checkbox">
              <span
                style={{ display: "inline-block" }}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(legalData.marketing?.body?.[0]?.value) }}
              ></span>
              <Tooltip
                placement="right"
                title={
                  <p
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(legalData.marketingTooltip?.body?.[0]?.value),
                    }}
                  />
                }
              >
                <InfoCircleOutlined className="public-form__comment-icon" />
              </Tooltip>
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Input name={["meta", "application_token"]} className="hidden" />
      <Input name={["meta", "applicant_token"]} className="hidden" />

      <Button disabled={!consent} loading={loading} type="primary" size="large" htmlType="submit">
        {t("g4rFormSubmit")}
      </Button>
    </Form>
  );
};

export default GuarantorForm;
