import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Select from "components/shared/form/CustomSelect";

import { getAssetBrands, getAssetModels, getAssetYears } from "services/classifierService";

const AssetSuggestionBlock = ({ formInstance, readOnly }) => {
  const { t } = useTranslation();

  const [brands, setBrands] = useState([]);

  const [selectedBrand, setSelectedBrand] = useState();
  const [selectedModel, setSelectedModel] = useState();

  const [models, setModels] = useState([]);
  const [years, setYears] = useState([]);

  useEffect(() => {
    // load classifs
    getAssetBrands().then(setBrands);
    getAssetYears().then(setYears);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    formInstance.resetFields([["lending", "model"]]);
    setSelectedModel(undefined);
    if (selectedBrand) {
      getAssetModels(selectedBrand).then(setModels);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrand]);

  return (
    <Row>
      <Col span="12">
        <Select
          showSearch
          label={t("g4rFormBrand")}
          name={["lending", "brand"]}
          value={selectedBrand}
          options={brands}
          onChange={setSelectedBrand}
          disabled={readOnly}
        />
      </Col>
      <Col span="12" />
      <Col span="12">
        <Select
          showSearch
          label={t("g4rFormModel")}
          name={["lending", "model"]}
          value={selectedModel}
          options={models.map((item) => {
            return {
              ...item,
              value: item.key,
            };
          })}
          onChange={setSelectedModel}
          disabled={readOnly}
        />
      </Col>
      <Col span="12" />
      <Col span="12">
        <Select
          showSearch
          label={t("g4rFormYearMade")}
          name={["lending", "manufacturing_year"]}
          options={years.map((item) => {
            if (item.key === "other") {
              return {
                key: "other",
                value: "0",
                title: t("g4rFormYearMadeOther"),
              };
            }
            return item;
          })}
          disabled={readOnly}
        />
      </Col>
    </Row>
  );
};

export default AssetSuggestionBlock;
